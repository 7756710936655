.coming-soon-coach-card {

  .coach-card {
    width: 300px;
    height: 350px;
    margin: 0 auto;
    display: block;
    position: relative;
    z-index: +500;
    overflow: hidden;

    .coach-card-overlay {
      width: 296px;
      height: 296px;
      background-color: rgba(39, 39, 39, 0.8);
      position: absolute;
      z-index: +90000;
      border-radius: 5px;
      left: 2px;
      top: 2px;

      h2 {
        text-align: center;
        height: 100%;
        position: relative;
        top: 40%;
        font-weight: 600;
        color: #767676FF;
      }
    }


    .coach-card-outline {
      position: absolute;
      top: 0;
      z-index: +1000;
      width: 300px;
      height: 300px;
      border: 2px solid rgb(118, 118, 118);
      border-radius: 5px;
      background-color: #101820;


      .coach-card-img {

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          border-bottom: 2px solid #EE2337;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .game-name {
        position: absolute;
        z-index: +1100;
        height: 35px;
        width: 150px;
        background: #101820;
        border-radius: 5px;
        border: 2px solid #253E5B;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        left: 75px;
        top: 182.5px;
      }

      .coach-card-content {
        width: 85%;
        margin: 0 auto;
        display: block;

        .coach-detail-row {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          filter: drop-shadow(0 0 0.5rem rgba(238, 35, 55, 0));
        }
      }

      .coach-name-main {
        display: flex;
        justify-content: center;
        padding-top: 35px;


        .coach-name {
          cursor: pointer;
          font-size: 20px;
        }

        .coach-language {
          padding-left: 20px;
        }

        .online-indicator {
          padding-left: 20px;
          padding-top: 5px;
        }
      }

      .coach-rating {
        text-align: center;
      }

      .coach-card-profile-click {
        width: 296px;
        height: 416px;
        position: absolute;
        background-color: rgba(16, 24, 32, 0);
        z-index: +500;
        cursor: pointer;
      }

      .coach-card-btn-main {
        display: flex;
        justify-content: space-between;
        align-items: center;


        .dashboard-secondary-btn {
          transition: 400ms;
          position: relative;
          z-index: +9000;
          background-color: #101820;
          filter: drop-shadow(0 0 0.35rem rgba(5, 183, 140, 0.2));

          &:hover {
            transition: 400ms;
            background: #0C574B;
          }
        }

      }


      .coach-price {
        color: #05B78C;
        font-weight: 600;
      }
    }
  }

  .hit-coach-name {
    font-weight: 500;
    font-size: 25px;
  }
}