.student-dashboard-page {
  .dashboard-header {
    border-bottom: 2px solid #EE2337;
  }

  .dashboard-header-row {
    display: flex;
    justify-content: space-between;

    .dashboard-primary-outline-btn {
      height: 50px;
      font-size: 15px;
      width: 150px;

    }

    .wallet-balance {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }


  .table {
    color: #D9D9D9;
  }

  .nav-pills .nav-link.active {
    background: #EE2337;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    color: #D9D9D9;
  }

  .dashboard-sidebar {
    .nav-link {
      color: #D9D9D9;
    }
  }

  .student-wallet-tab {
    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);

    }

    .table-border-bottom {
      padding-bottom: 15px;
    }

    .my-wallet-row {
      display: flex;
      justify-content: stretch;

      .my-wallet-title {
        width: 200px;
        font-weight: 500;
        color: #EE2337;
      }

      .dashboard-secondary-btn {
      }
    }

    @media (max-width: 442px) {
      .my-wallet-row {
        display: block;

        .dashboard-secondary-btn {
          margin-left: 0%;
          margin-top: 4%;
        }
      }
    }
  }
}
