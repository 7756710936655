@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");
@import url("react-datepicker/dist/react-datepicker.css");
@import url("components/react-tabs/react-tabs-custom.scss");

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  overflow-x: hidden;
  background: #101820 url('../public/assets/bg-texture.png');
}

.App {

  .page {
    min-height: 100vh;
    position: relative;
    top: 120px;
  }

  @media (max-width: 991.5px) {
    .page {
      min-height: 100vh;
      position: relative;
      top: 90px;
    }
  }


  .sweet-alert {
    background-color: #1d2834 !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  /*.page {
    padding-top: 50px;
    min-height: 700px;
  }*/


  /*.bg-main-color {
    background: #101820;
  }*/


  color: #D9D9D9;

  .clickable {
    cursor: pointer;
  }

  .primary-color {
    color: #EE2337;
  }

  .secondary-color {
    color: #05B78C;
  }

  h1 {
    font-weight: 800;
    font-size: 50px;
    text-transform: uppercase;
    filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));
  }

  @media (max-width: 700px) {
    h1 {
      font-size: 45px;
    }
  }

  .FPSLounge-subheading {
    font-weight: 600;
    font-size: 25px;
  }

  @media (max-width: 532px) {
    .FPSLounge-subheading {
      font-size: 20px;
    }
  }

  .FPS-primary-button {
    border-radius: 5px;
    color: #D9D9D9;
    background: #691C29;
    border: 2px solid #EE2337;
    font-weight: 600;
    font-size: 20px;
    width: 175px;
    height: 50px;
    filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

    &:hover {
      background: rgba(238, 35, 55);
      transition: 300ms;
    }
  }

  .form-control {
    font-weight: 500;
    font-size: 15px;
    background: rgba(238, 35, 55, 0);
    width: 100%;
    height: 45px;
    text-align: left;
    color: #D9D9D9 !important;
    border-radius: 5px;
    border: 2px solid rgba(238, 35, 55, 0.4);
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(238 35 55 / 25%);
  }

  .form-select {
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    height: 45px;
    text-align: left;
    background-color: rgba(238, 35, 55, 0);
    border: 2px solid rgba(238, 35, 55, 0.4);
    border-radius: 5px;
    color: #D9D9D9;
    position: relative;

    option {
      background: #D9D9D9;
      padding-top: 50px;
      padding-bottom: 50px;
      font-size: 20px;
      color: #EE2337;
      font-weight: 500;
    }


    option:hover {
      background: #05B78C;
    }
  }

  .btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(238, 35, 55, 0.4);
  }

  .form-select:focus {
    box-shadow: 0 0 0 0.25rem rgb(238 35 55 / 25%);
  }

  .form-select .op {
    background: rgb(255, 162, 0);
  }

  .dropdown-menu {
    margin-top: 10px;
    background: #101820;
    filter: drop-shadow(0 0 0.3rem rgba(238, 35, 55, 0.4));


    .dropdown-item {
      color: #D9D9D9;
    }

    .dropdown-item:hover {
      color: #EE2337;
      background-color: #101820;
    }
  }

  .form-check-input {
    background-color: rgba(16, 24, 32, 0);
    border: 2px solid rgba(238, 35, 55, 0.4);
  }

  .form-check-input:checked {
    background-color: #101820;
    border-color: #EE2337;
  }

  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(238 35 55 / 25%);
  }


  hr {
    background-color: rgba(238, 35, 55, 1);
    height: 2px;
    border: none;
  }

  .dashboard-title {
    font-weight: 600;
  }

  .dashboard-main-btn-row {
    width: 70%;
    margin: 0 auto;
    display: flex;
  }

  .dashboard-primary-btn {
    background: #691C29;
    border: 2px solid #EE2337;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

    &:hover {
      background: rgba(238, 35, 55);
      transition: 300ms;
    }
  }

  .dashboard-success-btn {
    background: #05B78C;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
  }

  .dashboard-primary-outline-btn {
    background: rgba(238, 35, 55, 0);
    border: 2px solid #EE2337;
    border-radius: 5px;
    color: #EE2337;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

    &:hover {
      background: #691C29;
      transition: 300ms;
    }
  }

  .dashboard-secondary-btn {
    border: 2px solid #05B78C;
    color: #05B78C;
    background: rgba(16, 24, 32, 0);


    &:hover {
      background: #0C574B;
      color: #05B78C;
    }
  }

  .dashboard-secondary-btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(5, 183, 140, 0.4);
  }

  .dashboard-box-outline {
    border: 2px solid rgba(238, 35, 55, 0.4);
    background: rgba(238, 35, 55, 0.1);
    border-radius: 5px;
  }

  .dashboard-content-outline {
    background: rgba(238, 35, 55, 0.1);
    border: 2px solid rgba(238, 35, 55, 0.4);
    border-radius: 5px;
  }

  .dashboard-padding-box {
    padding: 1.5rem 1.5rem 0.25rem 1.5rem;
  }

  .dashboard-sidebar {
    border-right: 2px solid #EE2337;
    height: 100%;

    .nav-item {
      height: 60px;
    }

    .nav-link {
      width: 95%;
    }
  }

  .react-select-dropdown {
    .react-select-dropdown-options__indicator {
      color: rgba(238, 35, 55, 0.4);
    }

    .react-select-dropdown-options__indicator-separator {
      background-color: rgba(238, 35, 55, 0.4);
    }

    .react-select-dropdown-options__control {
      background-color: rgba(16, 24, 32, 0) !important;
      border: 2px solid rgba(238, 35, 55, 0.4) !important;
    }

    .react-select-dropdown-options__control--is-focused {
      box-shadow: 0 0 0 0.25rem rgb(238 35 55 / 25%);
    }

    .react-select-dropdown-options__menu {
      background-color: #D9D9D9;
      box-shadow: 0 0 0 0.25rem rgb(238 35 55 / 25%);
      color: #101820;
      font-weight: 500;
    }

    .react-select-dropdown-options__single-value {
      color: #D9D9D9 !important;
    }

    .react-select-dropdown-options__multi-value {
      background-color: #EE2337;

      .react-select-dropdown-options__multi-value__label {
        color: #D9D9D9;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 991.5px) {
    .dashboard-sidebar {
      border-right: 0px;
      border-bottom: 2px solid #EE2337;
      width: 100%;

      .nav-link {
        width: 100%;
      }
    }
  }
}

.AppDesign {

  .bg-main-color {
    background: #101820;
  }

  color: #D9D9D9;

  .primary-color {
    color: #EE2337;
  }

  .secondary-color {
    color: #05B78C;
  }

  .FPSLounge-heading {
    font-weight: 800;
    font-size: 50px;
    text-transform: uppercase;
  }

  .FPSLounge-subheading {
    font-weight: 600;
    font-size: 25px;
  }


}

.sweet-alert {
  h2 {
    color: #EE2337 !important;
  }

  .btn-link {
    color: #05B78C;
    text-decoration: none;
    min-width: 100px;
    border-radius: 5px;
    border: 2px solid #05B78C;
  }

  .btn {
    background: #EE2337;
    border-radius: 5px;
    border: none;
    color: #D9D9D9;
    font-weight: 600;
    min-width: 100px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgba(165, 202, 234, 0) 0px 0px 8px !important;

    &:hover {
      filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));
      transition: 200ms;
    }
  }

  .payment-secondary-btn {
    background: #1d2834;
    border-radius: 5px;
    border: 2px solid #EE2337;
    color: #EE2337;
    font-weight: 600;
    min-width: 100px;
    box-shadow: rgb(0 0 0 / 8%) 0px 1px 1px inset, rgba(165, 202, 234, 0) 0px 0px 8px !important;

    &:hover {
      filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));
      transition: 200ms;
    }
  }
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.1;
}

.Toastify__toast-container {
  z-index: 1000000000 !important;
}

.text-align-center {
  text-align: center !important;
}

.popover {
  background-color: #1d2834;
  border: 2px solid rgba(238, 35, 55, 0.49);
  color: white;
  padding: 17px;
}
