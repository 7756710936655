.coach-profile-page {
  position: relative;

  .profile-bg-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 600px;
    width: 100%;
    position: absolute;
    top: -10%;
    z-index: +10;
  }

  .coach-profile-main {
    position: relative;
    z-index: +100;

    .coach-profile-desktop {
      display: block;
    }

    .coach-profile-mobile {
      display: none;
    }

    @media (max-width: 1199.5px) {
      .coach-profile-desktop {
        display: none;
      }

      .coach-profile-mobile {
        display: block;
      }
    }


    .profile-sub-heading {
      font-weight: 700;
      font-size: 20px;
      color: #EE2337;
    }
  }

  .coach-profile-desktop {

  }

  .profile-row {
    display: flex;
    margin-top: 8%;

    .profile-main-col-1 {

      .profile-pic {
        img {
          border: 2px solid #EE2337;
          border-radius: 5px;
        }


        &.coach-profile-pic-online {
          img {
            border: 2px solid #00c46f;
            border-radius: 5px;
          }
        }
      }


      .bio-box {
        width: 350px;
        border-radius: 5px;
        border: 2px solid #EE2337;

        .bio-description {
        }

        .coach-email-main {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .usernames-main {
          display: flex;
          justify-content: space-between;
        }

        .coach-profile-social-media-main {
          margin: 0 auto;
          display: inline-flex;
          flex-wrap: wrap;
          gap: 65px;
        }
      }

      .general-availability-box {
        width: 350px;
        border-radius: 5px;
        border: 2px solid #EE2337;

        .general-availability-main {
          .general-availability-row {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }


    .profile-main-col-2 {
      margin-top: 59px;
      margin-left: 3rem;
      width: 100%;

      .coach-name-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .coach-rank-img {
          margin-top: 2%;
        }

        .coach-name-col {

          .coach-name {
            font-weight: 800;
            font-size: 50px;
          }

          .coach-game {
            display: flex;
            font-weight: 500;
            font-size: 20px;
            color: #05B78C;
          }

        }

        .book-lesson-btn-main {
          margin-top: 4.5%;
          text-decoration: none;

          .book-lesson-btn {
            color: #D9D9D9;
            font-weight: 600;
            width: 175px;
            height: 50px;
            background: #691C29;
            border: 2px solid #EE2337;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

            &:hover {
              background: rgba(238, 35, 55);
              transition: 300ms;
            }
          }
        }
      }

      .profile-agent-row-box-outline {
        border-radius: 5px;
        border: 2px solid #EE2337;
        background: #101820;

        .profile-attribute-box {
          display: flex;
          justify-content: space-between;
        }
      }

      .main-points-row {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .main-points-box {
          border-radius: 5px;
          border: 2px solid #EE2337;
          width: 65%;
        }

        .achievements-box {
          width: 30%;
          margin-left: 5%;
          border-radius: 5px;
          border: 2px solid #EE2337;

          .achievements-row {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .book-a-lesson-row {
        .book-a-lesson-box {
          border-radius: 5px;
          border: 2px solid #EE2337;

          .session-btn-row {
            display: flex;
            justify-content: space-between;

            .session-btn-main {
              width: 47.5%;

              .session-btn {
                cursor: pointer;
                border-radius: 5px;
                font-size: 18px;
                font-weight: 700;
                color: #EE2337;
                background: rgba(238, 35, 55, 0.1);
                border: 2px solid rgba(238, 35, 55, 0.4);
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 75px;
              }
            }
          }

          .packages-row {
            display: flex;
            justify-content: space-between;

            .packages-box-active {
              background: rgba(238, 35, 55, 0.1);
              width: 30%;
              border-radius: 5px;
              border: 2px solid #EE2337;
              box-shadow: 0px 0px 20px rgba(238, 35, 55, 0.3);
            }

            .packages-box {
              background: rgba(238, 35, 55, 0.1);
              width: 30%;
              border-radius: 5px;
              border: 2px solid rgba(238, 35, 55, 0.4);
            }

            .package-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 180px;
            }

            .package-title {
              font-size: 18px;
              font-weight: 700;
              color: #EE2337;
            }

            .hours-quantity {
              display: flex;
              justify-content: center;
            }

            .discount {
              width: 150px;
              border-radius: 5px;
              background: #EE2337;
              font-weight: 700;
              color: #101820;
              margin: 0 auto;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
            }
          }

          .packages-btn-row {
            display: flex;
            justify-content: space-between;

            .book-now-btn-main {
              width: 31%;

              a {
                text-decoration: none;

                .book-now-btn {
                  background: #691C29;
                  border: 2px solid #EE2337;
                  font-weight: 700;
                  font-size: 20px;
                  border-radius: 5px;
                  color: #D9D9D9;
                  text-align: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  height: 75px;
                  filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

                  &:hover {
                    background: rgba(238, 35, 55);
                    transition: 300ms;
                  }

                  .book-now-btn-price {
                    font-size: 15px;
                    font-weight: 400;
                  }
                }

              }
            }

            .chat-with-coach-main {
              width: 31%;

              .chat-with-coach-btn {
                background: #101820;
                border: 2px solid #05B78C;
                font-weight: 700;
                font-size: 20px;
                border-radius: 5px;
                color: #05B78C;
                text-align: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 75px;
                transition: 300ms;
                filter: drop-shadow(0 0 0.35rem rgba(5, 183, 140, 0.2));


                &:hover {
                  background: #0C574B;
                  color: #05B78C;
                  transition: 300ms;
                }

                .chat-with-coach-btn-content-row {
                  margin: 0 auto;
                  width: 90%;
                  display: flex;
                  justify-content: center;

                  .chat-with-coach-img {
                    img {
                      width: 25px;
                    }
                  }
                }
              }
            }

            .submot-vod-btn-main {
              width: 31%;

              .submit-vod-btn {
                width: 100%;
                background: #691C29;
                border: 2px solid #EE2337;
                font-weight: 700;
                font-size: 20px;
                border-radius: 5px;
                color: #D9D9D9;
                text-align: center;
                height: 75px;
                filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

                &:hover {
                  background: rgba(238, 35, 55);
                  transition: 300ms;
                }
              }
            }

            @media (max-width: 1399.5px) {
              .book-now-btn-main {
                a {
                  .book-now-btn {
                    background: #691C29;
                    border: 2px solid #EE2337;
                    font-weight: 700;
                    font-size: 17px;
                  }
                }
              }
              .chat-with-coach-main {

                .chat-with-coach-btn {

                  font-size: 17px;

                  .chat-with-coach-btn-content-row {
                    width: 100%;

                    .chat-with-coach-img {
                      img {
                        width: 18px;
                      }
                    }
                  }
                }
              }

              .submot-vod-btn-main {

                .submit-vod-btn {
                  font-size: 17px;
                }
              }
            }

          }
        }
      }
    }
  }

  .coach-profile-mobile {
    height: 1300px;
    position: relative;
    top: 300px;

    .profile-picture {
      img {
        border: 2px solid #EE2337;
        border-radius: 5px;
      }

      .img-fluid {
        max-width: 100%;
      }
    }

    .coach-name-row {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .coach-rank-img {
        margin-top: 2%;
      }

      .coach-name-col {

        .coach-name {
          font-weight: 800;
          font-size: 50px;
        }

        .coach-game {
          font-weight: 500;
          font-size: 20px;
          color: #05B78C;
        }

      }

      .book-lesson-btn-main {
        text-decoration: none;

        .book-lesson-btn {
          color: #D9D9D9;
          font-weight: 600;
          width: 175px;
          height: 50px;
          background: #691C29;
          border: 2px solid #EE2337;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

          &:hover {
            background: rgba(238, 35, 55);
            transition: 300ms;
          }
        }
      }


    }

    .profile-agent-row-box-outline {
      width: 100%;
      margin-top: 10%;
      border-radius: 5px;
      border: 2px solid #EE2337;
      background: #101820;

      .profile-attribute-box {
        display: flex;
        justify-content: space-between;
      }

      @media (max-width: 575px) {
        .profile-attribute {
          padding-bottom: 5%;
        }

        .profile-attribute-box {
          display: block;
          justify-content: space-between;
        }
      }
    }

    .bio-box {
      width: 100%;
      border-radius: 5px;
      border: 2px solid #EE2337;

      .bio-description {
      }

      .coach-email-main {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .usernames-main {
        display: flex;
        justify-content: space-between;


      }
    }

    .main-points-box {
      border-radius: 5px;
      border: 2px solid #EE2337;
      width: 100%;
      height: 230px;
    }

    .general-availability-box {
      width: 100%;
      border-radius: 5px;
      border: 2px solid #EE2337;

      .general-availability-main {
        .general-availability-row {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .achievements-box {
      width: 100%;
      margin-left: 0%;
      border-radius: 5px;
      border: 2px solid #EE2337;

      .achievements-row {
        display: flex;
        justify-content: space-between;
      }
    }

    .book-a-lesson-row {
      .book-a-lesson-box {
        border-radius: 5px;
        border: 2px solid #EE2337;

        .session-btn-row {
          display: flex;
          justify-content: space-between;

          .session-btn-main {
            width: 47.5%;

            .session-btn {
              cursor: pointer;
              border-radius: 5px;
              font-size: 18px;
              font-weight: 700;
              color: #EE2337;
              background: rgba(238, 35, 55, 0.1);
              border: 2px solid rgba(238, 35, 55, 0.4);
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 75px;
            }
          }
        }

        .packages-row {
          display: flex;
          justify-content: space-between;

          .packages-box-active {
            background: rgba(238, 35, 55, 0.1);
            width: 30%;
            border-radius: 5px;
            border: 2px solid #EE2337;
            box-shadow: 0px 0px 20px rgba(238, 35, 55, 0.3);
          }

          .packages-box {
            background: rgba(238, 35, 55, 0.1);
            width: 30%;
            border-radius: 5px;
            border: 2px solid rgba(238, 35, 55, 0.4);
          }

          .package-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 180px;
          }

          .package-title {
            font-size: 18px;
            font-weight: 700;
            color: #EE2337;
          }

          .hours-quantity {
            display: flex;
            justify-content: center;
          }

          .discount {
            width: 150px;
            border-radius: 5px;
            background: #EE2337;
            font-weight: 700;
            color: #101820;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
          }
        }


        .book-now-btn-main {
          width: 100%;

          a {
            text-decoration: none;

            .book-now-btn {
              background: #691C29;
              border: 2px solid #EE2337;
              font-weight: 700;
              font-size: 20px;
              border-radius: 5px;
              color: #D9D9D9;
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 75px;
              filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

              &:hover {
                background: rgba(238, 35, 55);
                transition: 300ms;
              }

              .book-now-btn-price {
                font-size: 15px;
                font-weight: 400;
              }
            }

          }
        }

        .chat-with-coach-main {
          width: 100%;

          .chat-with-coach-btn {
            background: #101820;
            border: 2px solid #05B78C;
            font-weight: 700;
            font-size: 20px;
            border-radius: 5px;
            color: #05B78C;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 75px;
            transition: 300ms;
            filter: drop-shadow(0 0 0.35rem rgba(5, 183, 140, 0.2));


            &:hover {
              background: #0C574B;
              color: #05B78C;
              transition: 300ms;
            }

            .chat-with-coach-btn-content-row {
              margin: 0 auto;
              width: 90%;
              display: flex;
              justify-content: center;

              .chat-with-coach-img {
                img {
                  width: 25px;
                }
              }
            }
          }
        }

        .submot-vod-btn-main {
          width: 100%;

          .submit-vod-btn {
            width: 100%;
            background: #691C29;
            border: 2px solid #EE2337;
            font-weight: 700;
            font-size: 20px;
            border-radius: 5px;
            color: #D9D9D9;
            text-align: center;
            height: 75px;
            filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0.4));

            &:hover {
              background: rgba(238, 35, 55);
              transition: 300ms;
            }
          }
        }

        @media (max-width: 1399.5px) {
          .book-now-btn-main {
            a {
              .book-now-btn {
                background: #691C29;
                border: 2px solid #EE2337;
                font-weight: 700;
                font-size: 17px;
              }
            }
          }
          .chat-with-coach-main {

            .chat-with-coach-btn {

              font-size: 17px;

              .chat-with-coach-btn-content-row {
                width: 100%;

                .chat-with-coach-img {
                  img {
                    width: 18px;
                  }
                }
              }
            }
          }

          .submot-vod-btn-main {

            .submit-vod-btn {
              font-size: 17px;
            }
          }
        }

      }
    }

  }

  @media (max-width: 991.5px) {
    .coach-profile-mobile {
      height: 100%;
      position: relative;
      top: 100px;

      .profile-picture {
        img {
          border: 2px solid #EE2337;
          border-radius: 5px;
          margin: 0 auto;
          display: block;
        }

        .img-fluid {
          max-width: 50%;
        }
      }

      .coach-name-row {
        margin-top: 5%;
        margin-bottom: 5%;
      }

      .bio-box, .general-availability-box, .achievements-box {
        margin-bottom: 5%;
      }

      .main-points-box {
        height: unset;
        margin-bottom: 5%;
      }

      .achievements-box {
        width: 100%;
        margin-left: 0%;
        border-radius: 5px;
        border: 2px solid #EE2337;

        .achievements-row {
          display: flex;
          justify-content: space-between;
        }
      }

      .book-a-lesson-row {
        margin-bottom: 20%;
      }
    }
  }

  @media (max-width: 465px) {
    .coach-profile-mobile {
      .coach-name-row {
        display: block;
        justify-content: unset;
        width: 100%;

        .coach-name {
          margin: 0 auto !important;
          text-align: center;
        }

        .coach-game {
          display: flex;
          justify-content: center;
          font-weight: 500;
          font-size: 20px;
          color: #05B78C;
        }

        .book-lesson-btn {
          margin: 0 auto;
        }
      }
    }
  }

}
