.page.coaches-page {


  .upcoming-coaches-main {

    .join-coaches-waitlist-btn {
      .FPS-primary-button {
        filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0));
        width: 400px;
      }
    }

    h3 {
      color: #EE2337;
      font-weight: 600;
    }

    .upcoming-coach-cards-main {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .upcoming-coaches-form {
      .FPS-primary-button {
        filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0));
      }

      .comment-text-field {
        .form-control {
          height: unset;
        }
      }
    }
  }


  .nav {
    border-bottom: none !important;

    .nav-item {
      border-bottom: 2px solid #EE2337;
    }

    .nav-tabs .nav-link {
      color: #EE2337;
      font-size: 20px;
      font-weight: 600;
      width: 600px;
    }

    .nav-link:hover {
      border: 1px solid #EE2337;
    }

    .nav-link.active {
      background: #EE2337;
      color: #101820;
      border: none;
      border-bottom: 2px solid #EE2337;;
    }
  }


  .side-panel {
    border: 1px solid #EE2337;
    border-radius: 5px;
  }

  .ais-Hits-list {
    display: flex;
    flex-wrap: wrap;
  }

  .ais-Hits-item {
    border-radius: 10px;

  }

  .ais-Pagination-link {
    background: transparent;
    color: rgba(217, 217, 217, 0.68);
    border: 1px solid rgba(238, 35, 55);
    height: 40px;
  }

  .ais-HitsPerPage-select {
    background: transparent;
    color: rgba(255, 255, 255, 0.68);
  }

  .ais-SearchBox-form {
    background-color: transparent;

    .ais-SearchBox-input {
      border: 1px solid rgba(238, 35, 55);
      background-color: transparent;
      color: white;
    }
  }

  .coming-soon-coach-carousel {

    .react-multi-carousel-dot button {
      position: relative;
      top: 0px;
      background: #101820;
      border-color: #EE2337;
    }

    .react-multi-carousel-dot--active button {
      background: #EE2337;
    }

    .react-multiple-carousel__arrow--left {
      left: -1%;
    }

    .react-multiple-carousel__arrow::before {
      color: #EE2337;
      -webkit-text-stroke: 2px #EE2337;
      text-stroke: 2px #EE2337;
    }


    .react-multiple-carousel__arrow--right {
      right: -1%;
    }

    .react-multiple-carousel__arrow {
      background: none;
    }

    .react-multi-carousel-dot-list {
    }
  }
}
