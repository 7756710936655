.display-coach-card {
  width: 320px;
  margin: 0 auto;
  display: block;
  position: relative;
  z-index: +500;
  overflow: hidden;

  .general-availability-main {
    padding-top: 15px;

    .general-availability-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .coach-card {
    .coach-card-outline {
      top: 0;
      z-index: +1000;
      width: 320px;
      border: 2px solid #EE2337;
      border-radius: 5px;
      padding-bottom: 20px;

      .coach-card-img {
        img {
          width: 316px;
          height: 180px;
          object-fit: cover;
          border-bottom: 2px solid #EE2337;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }

      .game-name {
        position: absolute;
        z-index: +1100;
        height: 35px;
        width: 150px;
        background: #101820;
        border-radius: 5px;
        border: 2px solid #253E5B;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        left: 75px;
        top: 162.25px;
      }

      .coach-card-content {
        width: 85%;
        margin: 0 auto;
        display: block;

        .coach-point {
          height: 100px;

          ul {
            padding-left: 0px;

            li {
              list-style: none;
            }

            li::before {
              content: '';
              display: inline-block;
              margin-right: 10px;
              height: 12px;
              width: 12px;
              background-image: url("../../public/assets/squares-four.svg");

            }
          }
        }
      }

      .coach-name-main {
        height: 75px;
        display: flex;
        justify-content: center;
        padding-top: 35px;

        .coach-name {
          font-size: 20px;
        }

        .coach-language {
          padding-left: 20px;
        }
      }

      .coach-rating {
        text-align: center;
      }

      .coach-detail-row {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
      }

      .coach-points {
        display: flex;
      }

      .coach-price {
        color: #05B78C;
        font-weight: 600;
      }
    }

    &.coach-card-online {
      .coach-card-outline {
        border: 2px solid #00c46f;

        .coach-card-img {
          img {
            border-bottom: 2px solid #00c46f;
          }
        }
      }
    }
  }

}

@media (max-width: 450px) {
  .display-coach-card {
    width: 290px;
    height: 530px;

    .coach-card-outline {
      position: absolute;
      width: 290px;
      height: 490px;

      .coach-card-img {
        img {
          width: 287px;
        }
      }

      .game-name {
        left: 75px;
        top: 145.94px;
      }
    }
  }
}
