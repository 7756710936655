.student-dashboard-page {
  .dashboard-header {
    border-bottom: 2px solid #EE2337;
  }

  .dashboard-header-row {
    display: flex;
    justify-content: space-between;

    .dashboard-primary-outline-btn {
      height: 50px;
      font-size: 15px;
      width: 150px;
    }

    .dashboard-primary-outline-btn-inactive {
      height: 50px;
      font-size: 15px;
      width: 150px;
      background: rgba(58, 58, 58, 0);
      border: 2px solid rgb(53, 53, 53);
      border-radius: 5px;
      color: #585858;
      font-weight: 600;
      filter: drop-shadow(0 0 0.75rem rgba(238, 35, 55, 0));

      &:hover {
        background: rgba(58, 58, 58, 0);
        transition: 300ms;
      }
    }

    .wallet-balance {
      font-size: 20px;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }
  }
}
