.chat-page {
  #chat-section {
    height: 100% !important;

    .ce-chat-engine {
      border-radius: 15px !important;
      overflow: hidden !important;
    }

    .ce-chat-header-title {
      color: white;
      font-size: 18px !important;
    }

    .ce-chat-list-column {
      .ce-chat-list {
        padding-left: 10px !important;
        background-color: #18242f !important;

        .ce-chat-card {
          &.ce-active-chat-card {
            background-color: rgba(255, 99, 99, 0.13) !important;
          }

          &.ce-hovered-chat-card {
            background-color: rgba(255, 99, 99, 0.46) !important;
          }
        }

        .ce-chat-form {
          background-color: #18242f !important;

          .ce-default-button.ce-chat-form-button {
            display: none; /*Feature Hidden*/
            background-color: rgb(245, 34, 45) !important;
            color: white !important;
            border: transparent !important;
            padding: 2px 13px !important;
          }

          .ce-text-input {
            display: none; /*Feature Hidden*/
            background-color: #18242f;
            border: 1px solid rgba(240, 240, 240, 0.37) !important;
            color: white !important;
          }
        }
      }
    }

    .ce-chat-feed-column {
      background-color: #18242f !important;
    }

    #ce-send-message-button {
      background-color: #EE2337 !important;
      padding: 10px 20px !important;
    }

    .ce-chat-feed {
      background-color: #18242f !important;

      .ce-their-message-sender-username {
        color: rgba(255, 151, 151, 0.35) !important;
      }

      .ce-message-date-text {
        color: rgba(255, 255, 255, 0.35) !important;
      }

      .ce-their-message-timestamp {
        color: rgba(234, 234, 234, 0.35) !important;
      }

      .ce-message-form-input {
        background: transparent !important;
        border: none !important;
        caret-color: white;
        color: white !important;
      }

      .ce-attachment-icon {
        display: none; /*Feature Hidden*/
        color: white !important;
        background: none !important;
        border: none !important;
      }

      .ce-chat-header {
        background-color: #16212d !important;
      }

      .ce-message-list {
        background-color: #18242f !important;
      }
    }

    .ce-chat-settings {
      background-color: #18242f !important;

      .ce-chat-settings-title {
        font-size: 14px !important;
      }

      .ce-autocomplete-input {
        display: none; /*Feature Hidden*/
        background-color: #18242f;
        border: 1px solid rgba(240, 240, 240, 0.37) !important;
        color: white !important;
        margin-bottom: 10px;
        color: white !important;
      }

      .ce-dropdown-label {
        background-color: #18242f !important;
      }

      .ce-dropdown {
        border-top: 1px solid rgba(240, 240, 240, 0.37) !important;

        &#ce-settings-photos-dropdown {
          display: none; /*Feature Hidden*/
        }

        .ce-danger-button {
          background-color: rgb(245, 34, 45) !important;
          color: white !important;

          &.ce-member-delete-button {
            background-color: rgba(255, 255, 255, 0.6) !important;
            padding: 0 10px !important;
            border: none !important;
            color: red !important;
            font-weight: bold !important;
          }
        }
      }
    }


    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #2f4256 #18242f;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 12px;
    }

    *::-webkit-scrollbar-track {
      background: #18242f;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #2f4256;
      border-radius: 12px;
      border: 0px solid #ffffff;
    }
  }
}
