.community {
  position: relative;

  .community-bg-pattern {
    pointer-events: none;
    display: block;
    position: absolute;
    right: 0%;
    top: -30%;
  }

  .community-description {
    font-size: 25px;
    text-align: center;
    width: 55%;
    margin: 0 auto;
    display: block;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    .community-bg-pattern {
      display: none;
    }

    .community-description {
      font-size: 20px;
    }
  }

  .social-links-main {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin: 0 auto;
  }

  @media (max-width: 992px) {
    .community-description {
      width: 90%;
    }

    .social-links-main {
      width: 80%;
    }
  }
}

