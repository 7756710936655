.student-booking-list-item-reject {

  .card {
    background: rgba(238, 35, 55, 0.1);
    border: 2px solid rgba(238, 35, 55, 0.4);

    .card-body {
      padding: 0px 0px 0px 0px;
    }

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      padding-top: 15px;
    }

    .student-info-row {
      display: flex;

      .dashboard-coach-img img {
        border-radius: 50px;
      }

      .student-name {
        font-size: 25px;
        font-weight: 600;
      }

      .booking-mode {
        font-weight: 600;
        color: #EE2337;
      }
    }


  }

  .my-bookings-info-row {
    display: flex;

    .my-bookings-title {
      width: 200px;
      font-weight: 500;
      color: #EE2337;
    }
  }


  .my-bookings-col {
    padding-left: 2.5%;
    border-left: 1px solid rgba(238, 35, 55, 0.4);

    .my-bookings-row {
      display: flex;
    }

    .my-bookings-bold {
      font-weight: 500;
      width: 75px;
      color: #EE2337;
    }
  }

  @media (max-width: 991.5px) {
    .my-bookings-col {
      padding-left: 0%;
      border-left: 0px solid rgba(238, 35, 55, 0.4);
    }
  }
}

.student-booking-list-item-accept {

  .card {
    background: rgba(5, 183, 140, 0.1);
    border: 2px solid rgba(5, 183, 140, 0.4);

    .card-body {
      padding: 0px 0px 0px 0px;
    }

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      padding-top: 15px;
    }

    .student-info-row {
      display: flex;

      .dashboard-coach-img img {
        border-radius: 50px;
      }

      .student-name {
        font-size: 25px;
        font-weight: 600;
      }

      .booking-mode {
        font-weight: 600;
        color: #05B78C;
      }
    }


  }

  .my-bookings-info-row {
    display: flex;

    .my-bookings-title {
      width: 200px;
      font-weight: 500;
      color: #05B78C;
    }
  }


  .my-bookings-col {
    padding-left: 2.5%;
    border-left: 1px solid rgba(5, 183, 140, 0.4);

    .my-bookings-row {
      display: flex;
    }

    .my-bookings-bold {
      font-weight: 500;
      width: 75px;
      color: #05B78C;
    }

    .my-bookings-time {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 991.5px) {
    .my-bookings-col {
      padding-left: 0%;
      border-left: 0px solid rgba(5, 183, 140, 0.4);
    }
  }
}

.student-booking-list-item-pending {

  .card {
    background: rgba(255, 145, 0, 0.1);
    border: 2px solid rgba(255, 145, 0, 0.4);

    .card-body {
      padding: 0px 0px 0px 0px;
    }

    thead, tbody, tfoot, tr, td, th {
      border-bottom: 1px solid rgba(255, 255, 255, 0);
      padding-top: 15px;
    }

    .student-info-row {
      display: flex;

      .dashboard-coach-img img {
        border-radius: 50px;
      }

      .student-name {
        font-size: 25px;
        font-weight: 600;
      }

      .booking-mode {
        font-weight: 600;
        color: #FF9100;
      }
    }


  }

  .my-bookings-info-row {
    display: flex;

    .my-bookings-title {
      width: 200px;
      font-weight: 500;
      color: #FF9100;
    }
  }


  .my-bookings-col {
    padding-left: 2.5%;
    border-left: 1px solid rgba(255, 145, 0, 0.4);

    .my-bookings-row {
      display: flex;
    }

    .my-bookings-bold {
      font-weight: 500;
      width: 75px;
      color: #FF9100;
    }

    .my-bookings-time {
      padding-bottom: 10px;

    }
  }

  @media (max-width: 991.5px) {
    .my-bookings-col {
      padding-left: 0%;
      border-left: 0px solid rgba(255, 145, 0, 0.4);
    }
  }
}
